import 'regenerator-runtime/runtime';

// const pageContainer = document.querySelector('.page');

document.addEventListener('DOMContentLoaded', function() {
  // Your JavaScript code here
  
  function toggleSubNavMenu() {
    const mobileContainer = document.querySelector('.jupiter22-c-nav--mobile-open');
    const mobileButton = mobileContainer.querySelector('.jupiter22-js-nav__mobile-button');
    const hidden = mobileButton.getAttribute('aria-expanded');
  
  
    if (hidden === 'true') {
      mobileButton.setAttribute('aria-expanded', false);
      mobileContainer.querySelector('#mobile-nav-id').setAttribute('aria-hidden', true);
    }
    if(hidden === 'false') {
      mobileButton.setAttribute('aria-expanded', true);
      mobileContainer.querySelector('#mobile-nav-id').setAttribute('aria-hidden', false);
    }
  }
  
  const subMenu = document.querySelector('.jupiter22-js-nav__mobile-button');
  subMenu.addEventListener('click', () => toggleSubNavMenu());
});

class PrimaryNav {
  constructor(dataLayer) {
    this.el = document.querySelector('.primary-nav');
    this.content = this.el.querySelector('.primary-nav__content');
    this.hamburger = this.el.querySelector('.primary-nav__hamburger');
    this.logo = this.el.querySelector('.primary-nav__logo');
    this.account = this.el.querySelector('.primary-nav__account');
    this.links = this.el.querySelector('.primary-nav__links');
    this.flyout = this.el.querySelector('.primary-nav__flyout');
    this.subnav = document.querySelector('.sub-nav');
    this.megaMenus = this.el.querySelectorAll('.primary-nav__mega');
    this.overlay = document.querySelector('.modal-overlay');
    this.quoteNav = document.querySelector('.quote-detail-nav');

    if (this.account && this.account.getAttribute('href') === href) {
      this.account.setAttribute('title', window.Drupal.t('Log in'));
    }

    // Manually hiding the links to fix an iOS bug that showed nav on load.
    this.links.setAttribute('aria-hidden', 'true');

    this.dataLayer = dataLayer;
    this.prevScrollY = window.pageYOffset;
    this.lastDownScroll = 0;

    /*
    this.state = {
      hamburgerMenuOpen: false,
      hidden: false
    };
    */

    this.state = {
      hamburgerMenuOpen: false,
      hidden: false,
      subMenuOpen: false,
    };
  }

  init() {
    if (!document.querySelector('.primary-nav')) {
      return;
    }

    this.hamburger.addEventListener('click', () => this.toggleHamburgerMenu());
    


    if (this.overlay !== null) {
      this.overlay.addEventListener('click', event => this.closeMegaMenu(event));
    }

    if (document.querySelector('.jupiter22-c-nav') === null) {
      window.addEventListener('scroll', () => this.handleHiding());

      document.addEventListener('DOMContentLoaded', () => {
        if (document.querySelector('.jupiter22-c-nav') !== null) {
          window.removeEventListener('scroll', () => this.handleHiding());
        }
      });
    }

    // if (this.subnav) {
    //   pageContainer.classList.add('with-sub-nav');
    // }

    this.initExpandButtons();

    [...this.megaMenus].forEach((item) => {
      item.addEventListener('keyup', (event) => {
        if (event.key === 'Escape') {
          this.setCloseMegaMenu();
          this.el.querySelector('.primary-nav__expand[data-enabled="true"]').focus();
          this.el.querySelector('.primary-nav__expand[data-enabled="true"]').dataset.enabled = 'false';
          this.el.classList.remove('primary-nav--is-active');
        }
      });
    });

    this.nasdaqPlusConsiderations([
      'primary-nav__links',
      'primary-nav__search-icon',
      'primary-nav__search',
      'primary-nav__signin--link'
    ]);

    this.toggleFlyout();
    this.escapeFlyout();
    this.activeMobileNplusLinks();
    this.nasdaqPlusLinks();
  }

  nasdaqPlusConsiderations(elements) {
    if (window.location.href.match(/\/user\/register\?src=subscribe|\/user\/success/ig) !== null) {
      // remove elements for nasdaq plus views
      this.el.classList.add('page__nplusflow', 'plus-registration');
      elements.forEach((ele) => {
        const element = this.el.querySelector(`.${ele}`);

        if (ele === 'primary-nav__signin--link') {
          element.classList.add('primary-nav--nasdaq-plus-enable');
        }
        else {
          element.classList.add('primary-nav--nasdaq-plus-disable');
        }
      });
    }

    if (window.location.href.match(/\/plus\/subscription/ig) !== null) {
      // add Nasdaq+ logo link for subscription page
      this.logo.querySelector('a').href = '/';
      this.el.classList.add('page__nplusflow', 'page__nplus-subscription');
    }
  }

  toggleFlyout() {
    const toggle = this.el.querySelector('.primary-nav__account--loggedin');
    if (toggle) {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        toggle.classList.toggle('primary-nav__account--flyout');
      });
    }
  }

  escapeFlyout() {
    const loggedinElement = this.el.querySelector('.primary-nav__account--loggedin');
    const flyoutActiveText = 'primary-nav__account--flyout';

    if (loggedinElement) {
      this.flyout.addEventListener('keyup', (event) => {
        if (loggedinElement.classList.contains(`${flyoutActiveText}`)) {
          if (event.key === 'Escape') {
            loggedinElement.classList.remove(`${flyoutActiveText}`);
            loggedinElement.focus();
          }
        }
      });

      loggedinElement.addEventListener('keyup', (event) => {
        if (loggedinElement.classList.contains(`${flyoutActiveText}`)) {
          if (event.key === 'Escape') {
            loggedinElement.classList.remove(`${flyoutActiveText}`);
            loggedinElement.focus();
          }
        }
      });

      // Add a click event listener to the document object
      document.addEventListener('click', (event) => {
        if (document.querySelector(`.${flyoutActiveText}`) !== null) {
          if (!this.flyout.contains(event.target) && !loggedinElement.contains(event.target)) {
            loggedinElement.classList.remove(`${flyoutActiveText}`);
          }
        }
      });
    }
  }

  initExpandButtons() {
    const expandMegaMenuBtns = this.content.querySelectorAll('.primary-nav__expand');
    [...expandMegaMenuBtns].forEach((item) => {
      item.addEventListener('click', () => {
        // Load the status from the items attributes.
        const status = item.dataset.enabled;
        // Close the dropdown.
        if (status === 'true') {
          item.dataset.enabled = 'false';
          item.nextElementSibling.setAttribute('aria-hidden', 'true');
          this.el.classList.remove('primary-nav--is-active');
        }
        // Open the dropdown.
        else {
          this.closeAllExpandedMenus(expandMegaMenuBtns);
          item.nextElementSibling.setAttribute('aria-hidden', 'false');
          item.dataset.enabled = 'true';
          this.el.classList.add('primary-nav--is-active');
        }
      });

      item.addEventListener('keyup', (event) => {
        const status = item.dataset.enabled;
        if (status === 'true' && event.key === 'Escape') {
          item.dataset.enabled = 'false';
          item.nextElementSibling.setAttribute('aria-hidden', 'true');
          this.el.classList.remove('primary-nav--is-active');
        }
      });
    });

    /**
     * Close menu outside of nav
     */
    window.addEventListener('click', (e) => {
      if (document.querySelector('.primary-nav--is-active') !== null && !e.target.closest('.primary-nav__link')) {
        this.el.classList.remove('primary-nav--is-active');
        if (document.querySelector('.primary-nav__expand[data-enabled=true]') !== null) {
          document.querySelector('.primary-nav__expand[data-enabled=true]')
            .setAttribute('data-enabled', 'false');
          document.querySelector('.primary-nav__mega[aria-hidden=false]')
            .setAttribute('aria-hidden', 'true');
        }
      }
    });
  }

  closeAllExpandedMenus(expandMegaMenuBtns) {
    [...expandMegaMenuBtns].forEach((item) => {
      item.dataset.enabled = 'false';
      if (item.nextElementSibling) {
        item.nextElementSibling.setAttribute('aria-hidden', 'true');
      }
      this.el.classList.remove('primary-nav--is-active');
    });
  }

  toggleHamburgerMenu() {
    const hidden = this.links.getAttribute('aria-hidden');
    const watchlist = document.querySelector('.watchlist');

    if (hidden === 'true') {
      this.links.setAttribute('aria-hidden', 'false');
      this.el.classList.add('primary-nav--mobile-is-active');
      this.el.classList.add('primary-nav--is-active');
      document.body.classList.add('overlay-open');
      this.state.hamburgerMenuOpen = true;

      // watchlist should not be visible when overlay is open
      if (watchlist) {
        watchlist.classList.add('watchlist--hidden');
      }

      // Pause hiding behavior on scroll.
      this.state.autoHides = false;
    }
    else {
      this.links.setAttribute('aria-hidden', 'true');
      this.el.classList.remove('primary-nav--mobile-is-active');
      this.el.classList.remove('primary-nav--is-active');
      document.body.classList.remove('overlay-open');
      this.state.hamburgerMenuOpen = false;

      if (watchlist) {
        watchlist.classList.remove('watchlist--hidden');
      }

      // Resume hiding behavior on scroll.
      setTimeout(() => {
        this.state.autoHides = true;
      }, 500);
    }
  }


  closeHamburgerMenu() {
    if (this.state.hamburgerMenuOpen) {
      this.state.hamburgerMenuOpen = false;
      this.links.setAttribute('aria-hidden', 'true');
      this.el.classList.remove('primary-nav--mobile-is-active');
      this.el.classList.remove('primary-nav--is-active');
    }
  }

  megaMenuPos() {
    const primMenu = document.querySelector('.primary-nav__mega');
    if (primMenu !== null) {
      const val = primMenu.parentElement.offsetLeft;
      if (window.outerWidth < 1200 && window.outerWidth >= 768) {
        [...this.megaMenus].forEach((item) => {
          item.style.left = '10px';
        });
      }
      else {
        [...this.megaMenus].forEach((item) => {
          const a = val;
          item.style.left = `${a}px`;
        });
      }
    }
  }

  showOverlay() {
    const overlay = document.querySelector('.modal-overlay');
    if (this.overlay !== null) {
      overlay.style.display = 'block';
    }
    if (this.quoteNav !== null) {
      this.quoteNav.style.zIndex = '1';
    }
  }

  showMegaMenu(e) {
    if ('ontouchstart' in window || window.navigator.msMaxTouchPoints) {
      e.preventDefault();
      this.showOverlay();
      const parentNavtext = e.target.parentNode.parentNode;
      e.stopPropagation();

      const doc = parentNavtext.childNodes;
      let menu = null;
      const megaMenu = this.el.querySelectorAll('.primary-nav__mega');
      megaMenu.forEach((item) => {
        item.setAttribute('aria-hidden', 'true');
        item.parentNode.style.textDecoration = 'none';
        const parent = document.querySelector('.page');
        const modalView = parent.classList.contains('modal-view');
        if (!modalView) {
          parent.classList += ' modal-view';
        }
      });
      for (let i = 0; i < doc.length; i++) {
        const elem = doc[i];
        if (elem.className === 'primary-nav__mega') {
          menu = elem;
          menu.setAttribute('aria-hidden', 'false');
          menu.parentNode.style.textDecoration = 'underline';
          break;
        }
      }
    }
    return false;
  }

  setCloseMegaMenu() {
    const parentEle = this.el.querySelectorAll('.primary-nav__mega');
    for (let i = 0; i < parentEle.length; i++) {
      parentEle[i].setAttribute('aria-hidden', 'true');
    }
  }

  closeMegaMenu() {
    this.setCloseMegaMenu();
    this.links.style.height = '100%';
    this.hideOverlay();
  }

  hideOverlay() {
    const overlay = document.querySelector('.modal-overlay');
    if (this.quoteNav !== null) {
      this.quoteNav.style.zIndex = '9';
    }
    if (this.overlay !== null) {
      overlay.style.display = 'none';
    }
  }

  handleHiding() {
    // Get the scroll position.
    const scrollY = window.pageYOffset;

    // Determine the direction of the scroll.
    let directionUp = false;
    if (scrollY < this.prevScrollY) {
      directionUp = true;
    }

    // When scrolling up.
    if (directionUp === true) {
      // Determine how far up the user has scrolled since they started scrolling up.
      const scrolledUpAmount = this.lastDownScroll - scrollY;

      // If the nav is hidden and the user has scrolled up enough, un-hide the nav.
      if (this.state.hidden === true && scrolledUpAmount >= 100) {
        document.body.classList.remove('nav--hidden');
        // Adjust the nav bar for nasdaqplus when primary nav is shown
        if (document.querySelector('.nasdaq-plus-nav-bar')) {
          document.querySelector('.nasdaq-plus-nav-bar').classList.remove('plus-nav--hidden');
        }

        if (document.querySelector('.jupiter22-scorecard-parameters.active')) {
          document.querySelector('.jupiter22-scorecard-parameters.active').classList.remove('plus-nav--hidden');
        }

        this.state.hidden = false;
      }
    }

    // When scrolling down.
    else {
      // Save last downscroll position
      this.lastDownScroll = scrollY;

      if (scrollY > 400 && this.state.hidden === false) {
        // If the user scrolls down below the 800 pixel mark, hide the primary nav.
        document.body.classList.add('nav--hidden');
        // Adjust the nav bar for nasdaqplus when primary nav gets hidden
        if (document.querySelector('.nasdaq-plus-nav-bar')) {
          document.querySelector('.nasdaq-plus-nav-bar').classList.add('plus-nav--hidden');
        }

        if (document.querySelector('.jupiter22-scorecard-parameters.active')) {
          document.querySelector('.jupiter22-scorecard-parameters.active').classList.add('plus-nav--hidden');
        }

        this.state.hidden = true;
      }
    }

    // Update the prevScroll value.
    this.prevScrollY = scrollY;
  }

  handleScroll() {
    // Not having this was causing a bug where, on solution pages,
    // the menu being open, on mobile, could be closed by scrolling.
    if (document.body.classList.contains('overlay-open') === true) {
      return;
    }

    const scrollY = window.pageYOffset;

    if (scrollY > 80) {
      this.el.classList.add('primary-nav--secondary');
    }
    else {
      this.el.classList.remove('primary-nav--secondary');
    }

    this.closeHamburgerMenu();
  }

  activeMobileNplusLinks() {
    const isMobile = window.innerWidth <= 767;
    if (isMobile) {
      /* Filter links that are the current URL */
      const mobileLinks = Array
        .from(document.querySelectorAll('.primary-nav__link.nplus-user--item a'))
        .filter(l => (l.pathname === window.location.pathname && l.hash === window.location.hash));
      /* Set active class */
      mobileLinks.forEach(mobileLink => mobileLink.classList.add('primary-nav__mobile-account--nasdaq-plus-active'));
    }
  }

  nasdaqPlusLinks() {
    const loginLink = document.querySelector('[data-title="primary-nav-Login"]');
    const logoutLink = document.querySelector('[data-title="primary-nav-Logout"]');
    const aboutLink = document.querySelector('[data-title="primary-nav-About Nasdaq+"]');

    if (loginLink && logoutLink) {
      if (window.drupalSettings.user_info.loggedIn) {
        loginLink.style.display = 'none';
        logoutLink.style.display = 'list-item';
        if (!window.drupalSettings.user_info.user_nasdaq_plus && aboutLink) {
          aboutLink.style.display = 'list-item';
        }
      }
      else {
        loginLink.style.display = 'list-item';
        logoutLink.style.display = 'none';
      }
    }
  }
}

const primarynav = new PrimaryNav();
primarynav.init();

export default PrimaryNav;
